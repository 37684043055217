import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contentppt from './Contentppt'
// import ContentPPtEditSlideImg from '../../Components/ContentPPtEditSlideImg/ContentPPtEditSlideImg'
import ContentPPtEdit from '../ContentPptEdit/ContentPPtEdit'

function ContentPPTRouter() {
  return (
    <div>
        <Routes>
            <Route exact strict path="view" element={<Contentppt />} />
            <Route exact strict path="edit" element={<ContentPPtEdit />} />
        </Routes>
    </div>
  )
}

export default ContentPPTRouter
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  pagination,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_open, api_token } from "../../Utils/Network";

const columns = [
  { field: "id", headerName: "Chapter", flex: 1 },
  { field: "name", headerName: "Chapter Name", flex: 1 },
  {
    field: "noperiod",
    headerName: "No. of periods required",
    type: "number",
    flex: 1,
  },
];

const rows = [
  { id: 1, name: "John", age: 35, editableColumn: "Editable value 1" },
  { id: 2, name: "Jane", age: 24, editableColumn: "Editable value 2" },
  { id: 3, name: "Bob", age: 44, editableColumn: "Editable value 3" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function AddChapters() {
  const { gradeList, subjectList } = useContext(UserCredsContext);
  console.log(gradeList, subjectList, "gradeListtt");
  const [value, setValue] = React.useState(0);
  const [subjectValue, setSubjectValue] = useState(null);
  const [selectGrade, setSelectGrade] = useState({});
  const [data, setData] = useState(rows);
  const [editCell, setEditCell] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [subjectLists, setSubjectLists] = useState([]);
  const [editableRow, setEditableRow] = useState(-1); // -1 indicates no editable row
  const [accademic, setAccademic] = useState({});
  const [allSubject, setAllSubject] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [listingData, setListingData] = useState([]);

  console.log(rowData, "rowDattattata");
  console.log(columns, "columnsss");
  const navigate = useNavigate();

  const handleCellEditStart = (params) => {
    setEditCell(params);
  };

  useEffect(() => {
    getdefaultChapter();
    // getClasseswiseSubject();
    getListingData();
  }, []);

  useEffect(() => {
    setSubjectValue(subjectList[0]?.id);
    setSelectGrade(gradeList[0]);
  }, [subjectList]);

  useEffect(() => {
    getChapter();
    shift();
  }, [subjectValue, selectGrade]);

  const getListingData = () => {
    api_token
      .get(`/base/v1/grade_subject_listing/`)
      .then((res) => {
        console.log(res.data.data, "THUI")
        setListingData(res.data.data)
        setSubjectLists(res.data.data[0].subject)
      })
      .catch((err) => console.log(err))
  }
  const shift = () => {
    api_token
      .get(`calendar/v1/shifts/`)
      .then((res) => {
        accademicmaterix(res.data.data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const accademicmaterix = (val) => {
    api_token
      .get(`external/v1/academic_metrics?shift=${val}&grade=${selectGrade?.id}`)
      .then((res) => {
        console.log(res, "RRRRRRRR");
        setAccademic(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getdefaultChapter = () => {
    api_token
      .get(`cms/v1/create_default_chapters`)
      .then((res) => { })
      .catch((err) => console.log(err));
  };

  const getClasseswiseSubject = () => {
    api_token
      .get(`/base/v1/class`)
      .then((res) => {
        // debugger;
        console.log(res.data.data, gradeList, "rrrrrrrrrrrrr");
        const filteredSecondArray = removeDuplicateGradeObjects(res.data.data);
        setAllSubject(filteredSecondArray);
        console.log(filteredSecondArray, "dj");
        setSubjectLists(filteredSecondArray[0]?.subjects_detail);
        setFilterObj(filteredSecondArray[0])
      })
      .catch((err) => console.log(err));
  };

  function removeDuplicateGradeObjects(array) {
    // Use a Set to store unique grade values
    const uniqueGrades = new Set();
    // Filter the array to keep only unique grade objects
    return array.filter((item) => {
      // If the grade has already been encountered, return false (remove from array)
      if (uniqueGrades.has(item.grade)) {
        return false;
      }
      // Otherwise, add the grade to the set and return true (keep in array)
      uniqueGrades.add(item.grade);
      return true;
    });
  }
  const getChapter = () => {
    if (!selectGrade?.id && !subjectValue) return;
    api_token
      .get(`/cms/v1/chapter/?grade=${selectGrade?.id}&subject=${subjectValue}&page_size=30`)
      .then((res) => {
        console.log(res.data.data, "resDatata");
        let arr = [];
        let datas = res.data.data;
        datas.map((v, i) => {
          let val = {
            id: i + 1,
            ids: v?.id,
            name: v.title,
            no_of_periods: v?.no_of_periods,
          };
          arr.push(val);
        });
        setRowData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCellEditCommit = (params, e) => {
    console.log(params, e, "params");
    const editedRow = { ...params.row, [params.field]: params.value };
    const editedData = data.map((row) =>
      row.id === editedRow.id ? editedRow : row
    );
    setData(editedData);
    setEditCell(null);
  };

  const handleChange = (event, newValue) => {
    if (accademic?.total_periods_allocated > accademic?.total_periods) {
      alert("Allocated Period can not be greater then Total Periods");
      return;
    }
    setValue(newValue);
  };

  const handleTab = (v) => {
    // debugger;
    if (accademic?.total_periods_allocated > accademic?.total_periods) {
      // alert('Please Set this')
      return;
    }
    let data = allSubject.find((val) => val?.grade == v?.id);
    setFilterObj(data)
    setSubjectLists(v?.subject);
    setSelectGrade(v);
  };

  const handleNoperiodEdit = (rowIndex, noperiod) => {
    // Set the editable row to the rowIndex
    setEditableRow(rowIndex);
  };

  const handleNoperiodChange = (rowIndex, e) => {
    // Update the value in the rowData state
    // console.log(rowIndex, e.target.value, "updatedRowData[rowIndex]")
    const updatedRowData = [...rowData];
    updatedRowData[rowIndex].no_of_periods = e?.target?.value;
    setRowData(updatedRowData);

    api_token
      .post(`external/v1/update_chapters_period`, {
        chapters: [
          {
            id: updatedRowData[rowIndex].ids,
            no_of_periods_required: e?.target?.value || 0,
          },
        ],
      })
      .then((res) => {
        shift();
        api_token
          .get(`/base/v1/grade_subject_listing/`)
          .then((res) => {
            console.log(res.data.data,selectGrade,  "THUI")
            let datas = res.data.data.find((val) => val.id == selectGrade.id)
            setListingData(res.data.data)
            setSubjectLists(datas.subject)
          })
          .catch((err) => console.log(err))

        // api_token
        // .get(`/base/v1/class`)
        // .then((res) => {
        //   // debugger;
        //   console.log(res?.data?.data, gradeList, "rrrrrrrrrrrrr");
        //   const filteredSecondArray = removeDuplicateGradeObjects(res?.data?.data);
        //   setAllSubject(filteredSecondArray);
        //   let id = filteredSecondArray.find((val) => val?.id == filterObj?.id);
        //   setFilterObj(id);
        //   setSubjectLists(id?.subjects_detail);
        // })
        // .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    console.log(updatedRowData[rowIndex], "updatedRowData[rowIndex]");
  };

  const handleNoperiodBlur = (rowIndex) => {
    // Save changes and remove the editable state
    setEditableRow(-1);
  };

  const GotoGrade = () => {
    if (accademic?.total_periods_allocated > accademic?.total_periods) {
      alert("Allocated Period can not be greater then Total Periods");
      return;
    }
    navigate(`/onboard/timetable`);
    // navigate(`/onboard/selectShift`);
  };

  console.log(
    subjectValue,
    gradeList,
    subjectList,
    "subjectListsubjectListsubjectList"
  );

  const totalNoperiod =
    rowData.length > 0
      ? rowData?.reduce((total, item) => +total + +item.no_of_periods, 0)
      : "0";

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>

        <div className={styles.chapterMain}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {listingData &&
                  listingData.map((v, i) => (
                    <Tab
                      label={`${v?.grade?.title}`}
                      {...a11yProps(v)}
                      onClick={() => handleTab(v)}
                      style={{
                        color: "white",
                        fontSize: "23px",
                        fontWeight: "700",
                      }}
                    />
                  ))}
              </Tabs>
            </Box>
            <div className={styles.chapterTotal}>
              <div>
                <p>{accademic.academic_days}</p>
                <p>Total Academic Day</p>
              </div>
              -
              <div>
                <p>{accademic.holidays}</p>
                <p>Holiday</p>
              </div>
              -
              <div>
                <p>{accademic.weekend_count}</p>
                <p>Weekends</p>
              </div>
              -
              <div>
                <p>{accademic.contingency_periods}</p>
                <p>Contingency</p>
              </div>
              =
              <div>
                <p>{accademic.final_days}</p>
                <p>Final Days</p>
              </div>
            </div>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v, i) => (
              <TabPanel value={value} index={i}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {subjectLists &&
                        subjectLists.map((v, i) => (
                          <div
                            className={
                              subjectValue == v.id
                                ? styles.selectedTab
                                : styles.unselectedTab
                            }
                            onClick={() => setSubjectValue(v.id)}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p style={{ margin: "0" }}>{v.title}</p>{" "}
                            <p
                              style={{
                                margin: "0",
                                marginLeft: "10px",
                                fontSize: "14px",
                              }}
                            >{`${v?.no_of_periods} Periods`}</p>
                          </div>
                        ))}

                      <div className={styles.chapterT}>
                        <div style={{ marginRight: "10px" }}>Total Periods</div>
                        <div style={{ marginRight: "2px", fontSize: '19px' }}>
                          <p
                            style={{
                              fontSize: '20px',
                              color:
                                accademic.total_periods_allocated >
                                  accademic.total_periods
                                  ? "red"
                                  : "#a2ffa2",
                            }}
                          >
                            {accademic.total_periods_allocated}
                          </p>
                        </div>
                        /
                        <div style={{ marginLeft: "2px" }}>
                          <p style={{
                            fontSize: '20px',
                          }}>{accademic.total_periods}</p>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={8}>
                      <div
                        style={{ height: 500, width: "90%", color: "white" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              {columns.map((headRow) => (
                                <th>{headRow?.headerName}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              height: rowData.length === 0 ? "400px" : "auto",
                            }}
                          >
                            {rowData.length === 0 ? (
                              <tr>
                                <td
                                  colSpan="3"
                                  style={{
                                    textAlign: "center",
                                    margin: "14px",
                                  }}
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              <>
                                {rowData.map((rowItem, rowIndex) => (
                                  <tr key={rowIndex}>
                                    <td>{rowItem?.id}</td>
                                    <td>{rowItem?.name}</td>
                                    {/* <td>{rowItem?.noperiod}</td> */}

                                    <td>
                                      {editableRow === rowIndex ? (
                                        <input
                                          type="text"
                                          value={rowItem.no_of_periods}
                                          onChange={(e) =>
                                            handleNoperiodChange(rowIndex, e)
                                          }
                                          onBlur={() =>
                                            handleNoperiodBlur(rowIndex)
                                          }
                                        />
                                      ) : (
                                        <span
                                          onClick={() =>
                                            handleNoperiodEdit(
                                              rowIndex,
                                              rowItem.no_of_periods
                                            )
                                          }
                                        >
                                          {rowItem.no_of_periods}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td
                                    colSpan={2}
                                    style={{ textAlign: "center" }}
                                  >
                                    Total
                                  </td>
                                  <td>{totalNoperiod}</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            ))}
          </Box>
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddChapters;

const subject = [
  {
    id: 1,
    title: "English",
  },
  {
    id: 2,
    title: "Hindi",
  },
  {
    id: 3,
    title: "Physical Education",
  },
  {
    id: 4,
    title: "Art and Crafts",
  },
  {
    id: 5,
    title: "EVS",
  },
  {
    id: 6,
    title: "General Knowledge",
  },
  {
    id: 7,
    title: "Mathematics",
  },
];

import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import styles from "./Videos.module.css";
import videoImg from "../../Assets/Videos/videoImg.png";
import Ed from "../../Assets/ContentMangement/mindmap/Ed5.png";
import cnm from "../../Assets/ContentMangement/mindmap/cnm.png";
import Edit from "../../Assets/ContentMangement/mindmap/edit.png";
import Delete from "../../Assets/ContentMangement/mindmap/delete.png";

import videoDecimal from "../../Assets/ContentMangement/mindmap/Decimal.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomProgress from "../../Components/CustomProgress/CustomProgress";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { api_token, base_url, _access_token } from "../../Utils/Network";
import getCurrentTimeHours from "../../Utils/Utils";
import DrawerComp from "../../Components/DrawerComp/DrawerComp";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import InputField from "../../Components/Input/InputField";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DialogBox from "../../Components/DialogBox/DialogBox";
import BoxShadow from "../../Components/BoxShadow/BoxShadow";
import MySnackBar from "../../Components/MySnackBar/MySnackBar";
import Files from "../../File/videos.xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MillisecondsToTime from "../../Utils/Utils";
import addSubTopic from "../../Assets/ContentMangement/mindmap/addSubTopic.svg";
import deleteSubTopic from "../../Assets/ContentMangement/mindmap/deleteSubTopic.svg";
import drawerBack from "../../Assets/ContentMangement/ppt/drawerBack.png";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Days from "react-calendar/dist/cjs/MonthView/Days";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';

const ContentVideos = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [bookId, setBookId] = useState(null);
  const [topicList, setTopicList] = useState([]);
  const [subtopicList, setSubtopicList] = useState([]);
  const [videoTableData, setVideoTableData] = useState([]);
  const [refId, setRefId] = useState([]);
  const [startValue, setStartValue] = React.useState(dayjs("2023-10-04"));
  const [endValue, setEndValue] = React.useState(dayjs("2023-10-04"));
  const [postSubData, setPostSubData] = useState({
    video_id: "",
    subtopic_id: "",
  });
  const observer = useRef();

  const [loading, setLoading] = useState(false);

  // check this one...
  const [editSubData, setEditSubData] = useState({
    video_id: bookId,
    subtopic_id: null,
    start_time: "",
    end_time: "",
  });
  const [postSubDataErrors, setPostDataErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { content_selection } = useContext(UserCredsContext);
  const [chapterFilter, setChpFilter] = useState(null);
  const [getVideo, setVideo] = useState([]);
  const [file, setFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [drawerState, setDrawerState] = useState("");
  const [submitValue, setSubmitValue] = useState({
    id: "",
    subject_id: content_selection.subject,
    board_id: content_selection.board,
    grade_id: content_selection.grade,
    language_id: 1,
    chapter_id: null,
    topic: null,
    provider: 2,
    encoded_video: "",
    title: "",
    description: "",
  });
  const [filterObj, setFilterObj] = useState({
    subject: content_selection?.subject,
    grade: content_selection?.grade,
    board: content_selection?.board,
    page: 1,
  });

  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  const [deletedVideo, setDeletedVideo] = useState({});

  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: '',
    errorType: '',
  })
  const { vertical, horizontal } = alertDetails;

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: '',
      errorType: '',
    })
  }


  useEffect(() => {
    getVideoData();
  }, []);

  const getVideoData = (paramObj = { ...filterObj }, list = []) => {
    api_token
      .get(
        // `cms/v1/chapter_videos/?board=${content_selection.board}&grade=${content_selection.grade}&subject=${content_selection.subject}`
        `cms/v1/chapter_videos/`,
        { params: paramObj }
      )
      .then((res) => {
        // setVideo(res.data.data);

        if (res?.data?.next_page) paramObj.page = res?.data?.next_page;
        else delete paramObj.page;
        setVideo([...list, ...res.data.data]);
        setFilterObj(paramObj);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  //Topic List basing on Chapter Id...
  const getTopicData = (chapter_id) => {
    api_token
      .get(`cms/v1/topic?chapter=${chapter_id}`)
      .then((response) => {
        setTopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Subtopic List basing on Topic Id....
  const getSubTopicList = (topicId) => {
    api_token
      .get(
        `content/v1/book_subtopic/listing_subtopic_on_topic/?topic_id=${topicId}`
      )
      .then((response) => {
        setSubtopicList(response?.data?.data);
      })

      .catch((error) => {
        console.log(error, "error");
      });
  };

  //Video Table data basing on Video Id...
  const getVideoTableData = (id) => {
    api_token
      .get(`content/v1/video_subtopic/?video=${id}`)
      .then((response) => {
        setVideoTableData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleRedirect = (info) => {
    navigate("/dashboard/content/videos/videoPlayer", {
      state: info,
    });
  };

  const lastItemRef = useCallback(
    (node) => {
      if (loading || !getVideo.length) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && filterObj.page) {
          getVideoData(filterObj, getVideo);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, filterObj.page]
  );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const openVideo = () => {
    setSubmitValue({
      id: "",
      subject_id: content_selection.subject,
      board_id: content_selection.board,
      grade_id: content_selection.grade,
      language_id: 1,
      chapter_id: null,
      topic: "",
      provider: 2,
      encoded_video: "",
      // title: "",
      description: "",
    });
    setFile(null);
    // setChpFilter(null);
    setOpen(true);
    setDrawerState("addVideo");
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size here
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      const maxSizeMB = 100; // Example: 10 MB maximum size

      if (fileSizeInMB > maxSizeMB) {
        alert(`File size cannot exceed ${maxSizeMB} MB.`);
        e.target.value = null; // Clear the file input
        return;
      }
    setFile(file);
    }
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setSubmitValue({ ...submitValue, [name]: value });
    if (name == "chapter_id") {
      let chp = getVideo.findIndex((x) => {
        return x.id == value;
      });
      setChpFilter(chp);
    }

    if (name == "topic") {
      getSubTopicList(value);
    }
  };

  //newData function will call during open drawer....
  const newData = (data) => {
    const {
      id,
      language_id,
      provider,
      encoded_video,
      description,
      subject: subject_id,
      chapter: chapter_id,
      topic_v2: topic,
      board: board_id,
    } = data;
    setSubmitValue({
      id,
      language_id,
      provider,
      subject_id,
      chapter_id,
      encoded_video,
      description,
      topic,
      board_id,
    });
    let chp = getVideo.findIndex((x) => {
      return x.id == chapter_id;
    });
    setChpFilter(chp);
    setOpen(true);
    setPostSubData({ ...postSubData, video_id: id });
    getVideoTableData(id);
    setBookId(id);
    getSubTopicList(topic);
    getTopicData(chapter_id);
    setEditSubData({ ...editSubData, video_id: id });
    setPostDataErrors({});
  };

  const openBulk = () => {
    setOpenDialog(true);
  };

  //Uploading book file....
  const SubmitFile = async () => {
    let data = {
      file: file,
    };
    const formData = new FormData();
    if (data.file) formData.append("file", data.file);
    try {
      await axios({
        method: "Post",
        url: `${base_url}external/v1/sync/video/`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        headers: { Authorization: `Bearer ${_access_token}` },
      })
        .then((response) => {
          // setLoading(false)
          if (response.status === 200 || response.status === 201) {
            if (response.data.data) {
              alert("Video Bulk Data Uploaded Successfully");
              setFile(null);
              setOpenDialog(false);
              getVideoData();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } catch (error) {
      console.log(error);
    }
  };


  const submitData = async () => {
    let data = {
      ...submitValue,
      videos: file,
    };
    const formData = new FormData();
    console.log(file);
    if (data.topic) formData.append("topic", data.topic);
    if (data.title) formData.append("title", data.title);
    // if (data.encoded_video) formData.append("encoded_video", data.encoded_video);
    if (data.chapter_id) formData.append("chapter_id", data.chapter_id);
    // if (data.language_id) formData.append("language_id", data.language_id);
    if (data.grade_id) formData.append("grade_id", data.grade_id);
    if (data.subject_id) formData.append("subject_id", data.subject_id);
    if (data.board_id) formData.append("board_id", data.board_id);
    if (data.videos) formData.append("videos", data.videos);
    if (data.description) formData.append("description", data.description);

    console.log(data?.videos === null, "bkdhaidu9878687");


    if (data?.videos === null || data?.chapter_id === null) {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please Enter Valid Details",
        errorType: 'error'
      })
    }else{
      if (data.id === "") {
      try {
        await axios({
          method: "Post",
          url: `${base_url}cms/v1/videos/`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          headers: { Authorization: `Bearer ${_access_token}` },
        })
          .then((response) => {
            // setLoading(false)
            if (response.data.data) {
              alert("Video Added Successfully");
              setSubmitValue({
                subject_id: content_selection.subject,
                board_id: content_selection.board,
                grade_id: content_selection.grade,
                chapter_id: null,
                topic: "",
                provider: 1,
                encoded_video: "",
                title: "",
                description: "",
                id: null,
              });
              setFile(null);
              setOpen(false);
              getVideoData();
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios({
          method: "Patch",
          url: `${base_url}cms/v1/videos/${data.id}/`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          headers: { Authorization: `Bearer ${_access_token}` },
        })
          .then((response) => {
            // setLoading(false)
            if (response.data.data) {
              alert("Video Updated Successfully");
              // MySnackBar(true, "Video Updated Successfully", "success")
              setSubmitValue({
                subject_id: content_selection.subject,
                board_id: content_selection.board,
                grade_id: content_selection.grade,
                chapter_id: null,
                topic: "",
                provider: 1,
                encoded_video: "",
                title: "",
                description: "",
                id: null,
              });
              setFile(null);
              setOpen(false);
              getVideoData();
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } catch (error) {
        console.log(error);
      }
    }

    }
    
  };

  //post api and delete apis functionality///////////

  const handleChangeSubData = (e) => {
    const { name, value } = e.target;
    setPostSubData({ ...postSubData, [name]: value });
    setPostDataErrors({
      ...postSubDataErrors,
      [name]: "",
    });
  };

  //Post API For posting/adding new row data....

  //i)Validating post data...
  const validateForm = (finalData) => {
    const newErrors = {};

    if (finalData.subtopic_id == "" || finalData.subtopic_id == null) {
      newErrors.subtopic_id = "*Subtopic is required.";
    }
    if (
      finalData.start_time == "" ||
      finalData.start_time == null ||
      finalData.start_time == 0
    ) {
      newErrors.start_time = "*Start time is required.";
    }

    if (
      finalData.end_time == "" ||
      finalData.end_time == null ||
      finalData.end_time == 0
    ) {
      newErrors.end_time = "*End time is required.";
    }

    if (parseInt(finalData.start_time) > parseInt(finalData.end_time)) {
      //check this one
      newErrors.end_time = "*End time must be greater than Start time.";
    }

    console.log(finalData, newErrors, "newrarararra");
    setPostDataErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //ii)posting new data....

  const handlePostSubData = () => {
    let starthrs = startValue.$H;
    let startmins = startValue.$m;
    let startsec = startValue.$s;

    let startTime = (starthrs * 60 * 60 + startmins * 60 + startsec) * 1000;

    let endhrs = endValue.$H;
    let endmins = endValue.$m;
    let endsec = endValue.$s;

    let endTime = (endhrs * 60 * 60 + endmins * 60 + endsec) * 1000;

    const finalData = {
      ...postSubData,
      start_time: startTime,
      end_time: endTime,
    };
    if (validateForm(finalData)) {
      api_token
        .post(`content/v1/video_subtopic/add_videosubtopic/`, finalData) //content/v1/mindmap_subtopic/add_mindmapsubtopic/
        .then((response) => {
          if (response?.status == "200" || response?.status == "201") {
            getVideoTableData(bookId);
            setPostSubData({
              video_id: bookId,
              subtopic_id: null,
            });
            setPostDataErrors({});
            setStartValue(dayjs("2023-10-04"));
            setEndValue(dayjs("2023-10-04"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    // This code will run after postSubData has been updated
  }, [postSubData]);

  //Deleting Subtopic from the Table

  const handleDeleteSubTopic = (deletedSubtopicRow) => {
    let data = {
      video_id: deletedSubtopicRow?.video?.id,
      subtopic_id: deletedSubtopicRow?.subtopic?.id,
    };

    api_token
      .post(`content/v1/video_subtopic/delete_videosubtopic/`, data)

      .then((response) => {
        if (
          response?.status == "200" ||
          response?.status == "OK" ||
          response?.status == "201"
        ) {
          alert("Subtopic Deleted Successfully!");
          getVideoTableData(bookId);
          setPostDataErrors({});
        } else {
          throw new Error("erro");
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  //handle input change data on updating row data....

  const handleInputChange = (index, key, value, data) => {
    const hours = value.$H;
    const minutes = value.$m;
    const seconds = value.$s;

    let timeIddd = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

    const {
      subtopic: { id },
    } = data;
    if (id) {
      setRefId([...refId, id]);
      videoTableData[index].subtopic[key] = timeIddd;
      setVideoTableData([...videoTableData]);
    }
  };

  //Updating/Patching API row data.....

  const handleUpdateSubTopic = () => {
    const uniqueArray = Array.from(new Set(refId));
    let finalPatchData = [];
    uniqueArray.map((item) => {
      videoTableData.forEach((sub) => {
        const {
          subtopic: { id },
        } = sub;
        if (item === id) {
          finalPatchData.push(sub);
        }
      });
    });

    for (let data of finalPatchData) {
      let apiData = {
        video_subtopic_id: data?.id,
        video_id: data?.video?.id,
        subtopic_id: data?.subtopic?.id,
        start_time: data?.subtopic?.start_number,
        end_time: data?.subtopic?.end_number,
      };

      api_token
        .post(`content/v1/video_subtopic/edit_videosubtopic/`, apiData)
        .then((response) => {
          alert("Successfully Details Updated...");
          getVideoTableData(bookId);
        })
        .catch((error) => {
          alert("Please fill proper details....");
          console.log(error);
        });
    }

    // handlePostSubData();
    if (Object.values(postSubData).every((value) => value !== null)) {
      handlePostSubData();
    }


  };

  //DELETE API FOR DELETING Video....

  const handleDeleteVideo = (info) => {
    api_token
      .delete(`${base_url}cms/v1/videos/${info?.id}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 200) {
          alert("Video Deleted Successfully");
          setOpenVideoPopup(false)

          getVideoData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //DELETE POPUP  FOR DELETING VIDEO....

  const handleOpenDeleteMindmapPopup = (item) => {
    setOpenVideoPopup(true);
    setDeletedVideo(item);
  };

  return (
    <div>
      <div className={styles.Container}>
        {/* <div className={styles.headerTitle}>
          <h3 className={styles.title}>ICSE | </h3>
          <h3 className={styles.title}>Physics |</h3>
          <h3 className={styles.title}>Grade VI</h3>
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={styles.head}>Video</div>
          <div className={styles.buttonCon}>
            {/* <Button className={styles.bulkUpload} onClick={() => openBulk()}>
              Bulk Upload
            </Button> */}
            <Button className={styles.uploadVideo} onClick={openVideo}>
              Upload Video
            </Button>
          </div>
        </div>

        {getVideo &&
          getVideo.map((v, i) => (
            <div
              ref={i + 1 < getVideo.length ? null : lastItemRef}
              className={styles.videoContainer}
            >
              <span className={styles.videoTitle}>{`${i + 1}. ${v.title
                }`}</span>

              <Carousel responsive={responsive} swipeable={true}>
                {v.videos.map((info, index) => (
                  <div
                    style={{
                      position: "relative",
                      width: "95%",
                      margin: "20px",
                    }}
                  >
                    {info?.provider !== 1 && (
                      <EditIcon
                        style={{
                          position: "absolute",
                          bottom: "20px",
                          right: "13px",
                          zIndex: "999",
                          fontSize: "30px",
                          padding: "3px",
                          background: "white",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          newData(info);
                          setDrawerState("editVideo");
                        }}
                      />
                    )}

                    {info?.provider !== 1 && (
                      <img
                        src={deleteSubTopic}
                        style={{
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                          zIndex: "999",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleOpenDeleteMindmapPopup(info);
                        }}
                      />
                    )}

                    <div
                      key={info}
                      className={styles.cardMain}
                      onClick={() => handleRedirect(info)}
                      style={{ backgroundImage: `url(${info.thumbnail})` }}
                    >
                      {/* {info.thumbnail ? <img src={info.thumbnail} alt="image" className={styles.imgCard} /> : <img src={videoImg} alt="image" className={styles.imgCard} />} */}
                      {/* <img src={info.icons} alt="image" className={styles.imgLogo} /> */}
                      <div className={styles.customProgress}>
                        <div className={styles.topics}>{info.topic}</div>
                        {/* <CustomProgress value={30} /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          ))}
      </div>

      <div>
        <DrawerComp open={open} anchor="right">
          {drawerState === "editVideo" && (
            <div className={styles.BooksContainer} style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "19px", marginBottom: "10px" }}>
                  Edit Video
                </div>
                <div
                  onClick={() => {
                    setOpen(false);
                    setPostSubData({
                      video_id: "",
                      subtopic_id: null,
                    });
                    setStartValue(dayjs("2023-10-04"));
                    setEndValue(dayjs("2023-10-04"));
                  }}
                >
                  <img
                    src={drawerBack}
                    style={{ width: "30px", height: "30px", cursor: "pointer" }}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "20px" }}>
                {/* <InputField
                label="Topic Name"
                width="400px"
                value={submitValue?.topic}
                name="topic"
                onChange={handleData}
              /> */}

                <FormControl fullWidth style={{ width: "400px" }}>
                  <InputLabel id="demo-simple-select-label">Chapter</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={chapterObj.chapter_id}
                    value={getVideo[chapterFilter]?.id}
                    disabled
                    label="Chapter"
                    name="chapter_id"
                    onChange={handleData}
                  >
                    {getVideo &&
                      getVideo.map((v, i) => (
                        <MenuItem value={v.id}>{v.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div style={{ marginBottom: '20px' }}>

              <InputField
                label="Encoded Url"
                width="300px"
                value={submitValue?.encoded_video}
                name="encoded_video"
                onChange={handleData}
              />
            </div> */}
              {/* <div style={{ marginBottom: '20px' }}>

              <InputField
                label="Title"
                width="300px"
                value={submitValue?.title}
                name="title"
                onChange={handleData}
              />
            </div> */}
              <div style={{ marginBottom: "20px" }}>
                <InputField
                  label="Description"
                  width="400px"
                  multiline
                  rows="3"
                  value={submitValue?.description || ""}
                  name="description"
                  onChange={handleData}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                {/* <FormControl fullWidth style={{ width: "400px" }}>
                <InputLabel id="demo-simple-select-label">Chapter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={getVideo[chapterFilter]?.id}
                  label="Chapter"
                  name="chapter_id"
                  onChange={handleData}
                >
                  {getVideo &&
                    getVideo.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl> */}
                <FormControl fullWidth style={{ width: "400px" }}>
                  <InputLabel id="demo-simple-select-label">Topic</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Topic"
                    value={submitValue?.topic}
                    name="topic"
                    onChange={handleData}
                  >
                    {topicList &&
                      topicList.map((topic, i) => (
                        <MenuItem value={topic.id}>{topic.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <div>Upload Video</div>
                <input type="file" accept="file/*" onChange={handleChange} />
              </div>

              <div style={{ marginTop: "20px" }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: "black", color: "#fff" }}>
                      <TableRow>
                        {/* <TableCell
                        sx={{ color: "#fff", fontSize: "20px" }}
                        align="center"
                      >
                        Topic Name
                      </TableCell> */}

                        <TableCell
                          sx={{ color: "#fff", fontSize: "20px" }}
                          align="center"
                        >
                          Subtopic
                        </TableCell>
                        <TableCell
                          sx={{ color: "#fff", fontSize: "20px" }}
                          align="center"
                        >
                          Start Time
                        </TableCell>
                        <TableCell
                          sx={{ color: "#fff", fontSize: "20px" }}
                          align="center"
                        >
                          End Time
                        </TableCell>
                        <TableCell
                          sx={{ color: "#fff", fontSize: "20px" }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {videoTableData?.length > 0 ? (
                        <>
                          {videoTableData.map((data, index) => {
                            return (
                              <TableRow>
                                {/* <TableCell
                                align="center"
                                style={{ fontSize: "16px" }}
                              >
                                {data?.subtopic?.topic_name}
                              </TableCell> */}

                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  {/* {data?.subtopic?.title} */}
                                  {/* <FormControl
                                  fullWidth
                                  style={{ width: "400px" }}
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Subtopic Name
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Subtopic Name"
                                    value={data?.subtopic?.id}
                                    name="subtopic_id"
                                    onChange={handleEditSubTopic}
                                    style={{ width: "100%" }}
                                  >
                                    {subtopicList &&
                                      subtopicList.map((subtopic, i) => (
                                        <MenuItem value={subtopic.id}>
                                          {subtopic.title}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl> */}
                                  <InputField
                                    value={data?.subtopic?.title}
                                    // label="Start Page"
                                    width="100%"
                                    name="start_number"
                                    onChange={handleChangeSubData}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  {/* {MillisecondsToTime(
                                  data?.subtopic?.start_number
                                )} */}

                                  {/* <TextField                                     //work time from here.....
                                  id="standard-basic"
                                  // label="Standard"
                                  // variant="standard"
                                  style={{ textAlign: "center" }}
                                  value={MillisecondsToTime(
                                    data?.subtopic?.start_number
                                  )}
                                  onChange={handleEditSubTopic}
                                />  */}
                                  <div
                                    className={styles.inputbox}
                                    style={{ width: "100%" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <TimePicker
                                          ampm={false}
                                          openTo="seconds"
                                          views={[
                                            "hours",
                                            "minutes",
                                            "seconds",
                                          ]}
                                          inputFormat="HH:mm:ss"
                                          mask="__:__:__"
                                          label="Start Time"
                                          // value={new Date(
                                          //   data?.subtopic?.start_number
                                          // )}

                                          value={(() => {
                                            const date = new Date(0); // Create a Date object at epoch (January 1, 1970)
                                            date.setHours(0);
                                            date.setMinutes(0);
                                            date.setSeconds(0);
                                            date.setUTCMilliseconds(
                                              data?.subtopic?.start_number
                                            );
                                            // date.setMilliseconds(data?.subtopic?.start_number); // Set milliseconds
                                            return date;
                                          })()}
                                          // onChange={(newValue) => {
                                          //   setUpdateStartTime(newValue);
                                          //   handleInputChange(
                                          //     index,
                                          //     "start_number",
                                          //     updateStartTime,
                                          //     data
                                          //   );
                                          // }}
                                          onChange={(newValue) => {
                                            let updateInitialVal =
                                              dayjs(newValue);

                                            handleInputChange(
                                              index,
                                              "start_number",
                                              updateInitialVal,
                                              data
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </div>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  {/* {MillisecondsToTime(
                                  data?.subtopic?.end_number ||
                                    data?.subtopic?.start_number
                                )} */}
                                  {/* <TextField
                                  id="standard-basic"
                                  // label="Standard"
                                  // variant="standard"
                                  style={{ textAlign: "center" }}
                                  // value={
                                  //   data?.subtopic?.end_number ||
                                  //   data?.subtopic?.start_number
                                  // }
                                  value={MillisecondsToTime(
                                    data?.subtopic?.end_number ||
                                      data?.subtopic?.start_number
                                  )}
                                  onChange={handleEditSubTopic}
                                /> */}

                                  <div
                                    className={styles.inputbox}
                                    style={{ width: "100%" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Stack spacing={3}>
                                        <TimePicker
                                          ampm={false}
                                          openTo="seconds"
                                          views={[
                                            "hours",
                                            "minutes",
                                            "seconds",
                                          ]}
                                          inputFormat="HH:mm:ss"
                                          mask="__:__:__"
                                          label="End Time"
                                          // value={new Date(
                                          //   data?.subtopic?.start_number
                                          // )}

                                          value={(() => {
                                            const date = new Date(0); // Create a Date object at epoch (January 1, 1970)
                                            date.setHours(0);
                                            date.setMinutes(0);
                                            date.setSeconds(0);
                                            date.setUTCMilliseconds(
                                              data?.subtopic?.end_number
                                            );
                                            // date.setMilliseconds(data?.subtopic?.start_number); // Set milliseconds
                                            return date;
                                          })()}
                                          onChange={(newValue) => {
                                            let updatedFinalVal =
                                              dayjs(newValue);

                                            handleInputChange(
                                              index,
                                              "end_number",
                                              updatedFinalVal,
                                              data
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  <img
                                    src={deleteSubTopic}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleDeleteSubTopic(data)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow>
                            <TableCell
                              // align="center"
                              style={{ fontSize: "16px" }}
                            >
                              <FormControl
                                fullWidth
                                error={postSubDataErrors.subtopic_id && true}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Subtopic Name
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Subtopic Name"
                                  value={postSubData?.subtopic_id}
                                  name="subtopic_id"
                                  // onChange={(topic) => {
                                  //     handleSelect(topic)

                                  // }}
                                  onChange={handleChangeSubData}
                                  style={{ width: "100%" }}
                                >
                                  {subtopicList &&
                                    subtopicList.map((subtopic, i) => (
                                      <MenuItem value={subtopic.id}>
                                        {subtopic.title}
                                      </MenuItem>
                                    ))}
                                </Select>

                                <FormHelperText>
                                  {postSubDataErrors.subtopic_id}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: "16px" }}
                            >
                              <div
                                className={styles.inputbox}
                                style={{ width: "100%" }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <TimePicker
                                      ampm={false}
                                      openTo="hours"
                                      views={["hours", "minutes", "seconds"]}
                                      inputFormat="HH:mm:ss"
                                      mask="__:__:__"
                                      label="Start Time"
                                      value={startValue}
                                      onChange={(newValue) => {
                                        setStartValue(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={postSubDataErrors.start_time}
                                          helperText={
                                            postSubDataErrors.start_time
                                          }
                                        />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>

                              {/* {postSubDataErrors.start_time && ( //change start_time time field
                              <p style={{ color: "red" }}>
                                *{postSubDataErrors.start_time}
                              </p>
                            )} */}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ fontSize: "16px" }}
                            >
                              <div
                                className={styles.inputbox}
                                style={{ width: "100%" }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <TimePicker
                                      ampm={false}
                                      openTo="hours"
                                      views={["hours", "minutes", "seconds"]}
                                      inputFormat="HH:mm:ss"
                                      mask="__:__:__"
                                      label="End Time"
                                      value={endValue}
                                      onChange={(newValue) => {
                                        setEndValue(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={postSubDataErrors.end_time}
                                          helperText={
                                            postSubDataErrors.end_time
                                          }
                                        />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                              {/* {postSubDataErrors.end_time && (
                              <p style={{ color: "red" }}>
                                *{postSubDataErrors.end_time}
                              </p>
                            )} */}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontSize: "16px" }}
                            >
                              <img
                                src={addSubTopic}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  cursor: "pointer",
                                }}
                                onClick={handlePostSubData}
                              />{" "}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell
                            // align="center"
                            style={{ fontSize: "16px" }}
                          >
                            <FormControl
                              fullWidth
                              error={postSubDataErrors.subtopic_id}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Subtopic Name
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Subtopic Name"
                                name="subtopic_id"
                                value={postSubData?.subtopic_id}
                                onChange={handleChangeSubData}
                                style={{ width: "100%" }}
                              >
                                {subtopicList &&
                                  subtopicList.map((subtopic, i) => (
                                    <MenuItem value={subtopic.id}>
                                      {subtopic.title}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText>
                                {postSubDataErrors.subtopic_id}
                              </FormHelperText>
                            </FormControl>

                            {/* {postSubDataErrors.subtopic_id && (
                            <p style={{ color: "red" }}>
                              *{postSubDataErrors.subtopic_id}
                            </p>
                          )} */}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "16px" }}
                          >
                            <div
                              className={styles.inputbox}
                              style={{ width: "100%" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                  <TimePicker
                                    ampm={false}
                                    openTo="hours"
                                    views={["hours", "minutes", "seconds"]}
                                    inputFormat="HH:mm:ss"
                                    mask="__:__:__"
                                    label="Start Time"
                                    value={startValue}
                                    onChange={(newValue) => {
                                      setStartValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={postSubDataErrors.start_time}
                                        helperText={
                                          postSubDataErrors.start_time
                                        }
                                      />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </div>
                            {/* {postSubDataErrors.start_time && (
                            <p style={{ color: "red" }}>
                              *{postSubDataErrors.start_time}
                            </p>
                          )} */}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "16px" }}
                          >
                            <div
                              className={styles.inputbox}
                              style={{ width: "100%" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                  <TimePicker
                                    ampm={false}
                                    openTo="hours"
                                    views={["hours", "minutes", "seconds"]}
                                    inputFormat="HH:mm:ss"
                                    mask="__:__:__"
                                    label="End Time"
                                    value={endValue}
                                    onChange={(newValue) => {
                                      setEndValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={postSubDataErrors.end_time}
                                        helperText={postSubDataErrors.end_time}
                                      />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </div>
                            {/* {postSubDataErrors.end_time && (
                            <p style={{ color: "red" }}>
                              *{postSubDataErrors.end_time}
                            </p>
                          )} */}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: "16px" }}
                          >
                            <img
                              src={addSubTopic}
                              style={{
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              onClick={handlePostSubData}
                            />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <div>
                          <Button
                            onClick={() => {
                              handleUpdateSubTopic();
                            }}
                            className={styles.btns}
                            style={{
                              alignSelf: "center",
                              marginBottom: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* <div>
              <Button
                className={styles.btns}
                variant="contained"
                onClick={() => submitData()}
              >
                Add
              </Button>
            </div> */}
            </div>
          )}
          {drawerState === "addVideo" && (
            <div className={styles.BooksContainer} style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <div style={{ fontSize: "19px", marginBottom: "10px" }}>
                  Add Video
                </div>
                <div
                  onClick={() => {
                    setOpen(false);
                    setPostSubData({
                      book_id: "",
                      subtopic_id: null,
                      start_number: "",
                      end_number: "",
                    });
                  }}
                >
                  <img
                    src={drawerBack}
                    style={{ width: "30px", height: "30px", cursor: "pointer" }}
                  />
                </div>
              </div>

              {/* <div style={{ marginBottom: "20px" }}>
                <InputField
                  label="Topic Name"
                  width="300px"
                  value={submitValue?.topic}
                  name="topic"
                  onChange={handleData}
                />
              </div> */}

              {/* <div style={{ marginBottom: "20px" }}>
                <InputField
                  label="Encoded Url"
                  width="300px"
                  value={submitValue?.encoded_video}
                  name="encoded_video"
                  onChange={handleData}
                />
              </div> */}

              {/* <div style={{ marginBottom: '20px' }}>

 

              <InputField

                label="Title"

                width="300px"

                value={submitValue?.title}

                name="title"

                onChange={handleData}

              />

            </div> */}

              {/* <div style={{ marginBottom: "20px" }}>
                <InputField
                  label="Description"
                  width="300px"
                  multiline
                  rows="3"
                  value={submitValue?.description}
                  name="description"
                  onChange={handleData}
                />
              </div> */}

              <div style={{ marginBottom: "10px" }}>
                <FormControl fullWidth style={{ width: "300px" }}>
                  <InputLabel id="demo-simple-select-label">Chapter</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getVideo[chapterFilter]?.id}
                    label="Chapter"
                    name="chapter_id"
                    onChange={handleData}
                  >
                    {getVideo &&
                      getVideo.map((v, i) => (
                        <MenuItem value={v.id}>{v.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <div>Select file</div>

                <input
                  type="file"
                  accept=".mp4"
                  onChange={handleChange}
                />
              </div>

              <div>
                <Button className={styles.btns} onClick={() => submitData()}>
                  Add
                </Button>
              </div>
            </div>
          )}
        </DrawerComp>
      </div>

      <div>
        <DialogBox
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          dataSend={() => SubmitFile()}
        >
          <div>
            <h3>Upload File</h3>
            <p>
              Get Sample File here
              <a href={Files} download>
                {" "}
                Click to download
              </a>
            </p>
            <input type="file" accept="file/*" onChange={handleChange} />
          </div>
        </DialogBox>
      </div>


      <div>

        <Modal
          open={openVideoPopup}
          // onClose={handleClose}
          aria-labelledby="feedback-modal-title"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}

            className={styles.popUpCon}
            style={{
              textAlign: "center"
            }}
          >
            <div style={{ width: "100%" }}>
              <h3 className={styles.editHead}>
                Are you sure you want to delete?
              </h3>
              <img
                src={deletedVideo?.thumbnail}
                className={styles.deletedEbookImg}
                alt="deleted_video"
              />
              <div
                className={styles.btnsContainer}
              >
                <button
                  className={styles.deleteBtn}

                  onClick={() => handleDeleteVideo(deletedVideo)}
                >
                  Delete
                </button>
                <button
                  className={styles.cancelBtn}
                  onClick={() => setOpenVideoPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </Modal>


        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertDetails.open}
          onClose={handleClose}
          autoHideDuration={6000}>
          <Alert severity={alertDetails.errorType} sx={{ width: '100%' }}>
            {alertDetails.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ContentVideos;

const videoData = [
  {
    id: 1,
    image: videoImg,
    icons: Ed,
  },
  {
    id: 2,
    image: videoImg,
    icons: cnm,
  },
  {
    id: 3,
    image: videoImg,
    icons: Ed,
  },
  {
    id: 4,
    image: videoImg,
    icons: cnm,
  },
];

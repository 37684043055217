import React, { useState, useEffect } from "react";
import CustomCalendar from "../../../Components/CustomCalendar/CustomCalendar";
import { convertCalendarDate, getCurrentDateTime } from "../../../Utils/Utils";
import { api_token } from "../../../Utils/Network";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styles from "./index.module.css";
import moment from "moment";
import DrawerComp from "../../../Components/DrawerComp/DrawerComp";

function Holiday() {
  const [calendarData, setCalendarData] = useState([]);
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());
  const [dateTime, setDateTime] = useState();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState({});
  const [eventCategory, setEventCategory] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [classDetails, setClassDetail] = useState({
    event_type_id: "",
    title: "",
    description: "",
    start: "",
    end: "",
    commence: "",
    conclude: "",
    event_for: null,
    chapter_id: null,
    classes: null,
    subject_id: null,
    event_category_id: null,
    teacher: null,
  });
  console.log(dateTime, "date");
  useEffect(() => {
    let mnth;
    let yrs;
    if (dateTime) {
      const date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
    } else {
      const { month, year } = currenctDate;
      mnth = month + 1;
      yrs = year;
    }
    getListing(mnth, yrs);
  }, [dateTime]);

  useEffect(() => {
    getEventCategory();
    getEventType();
  }, []);

  const getEventCategory = () => {
    api_token
      .get(`/base/v1/event_category`)
      .then((res) => {
        setEventCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getEventType = () => {
    api_token
      .get(`/base/v1/event_type`)
      .then((res) => {
        setEventType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClicked = () => {
    setOpen(!open);
  };
  const getListing = (month, year) => {
    api_token
      .get(`calendar/v1/events?event_type=3&month=${month}&year=${year}`)
      .then((response) => {
        console.log(response);
        const data = convertCalendarDate(response.data.data);
        setCalendarData(data);
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChange = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };

  // edit api work
  const handleCalendarDataUpdate = (data) => {
    // Handle the received data here in the parent component
    console.log("Received data in parent component:", data);

    // Example: Update state with received data
    // setCalendarData(data);
    // setEditData(data)
    handleClicked(data?.id);
    setClassDetail({
      event_type_id: data?.event_type?.id,
      title: data?.title?.props?.children[0]?.props?.children[1],
      description: data?.description,
      start: data?.date,
      end: data?.date,
      commence: data?.commence,
      conclude: data?.conclude,
      event_for: data?.event_for,
      chapter_id: data?.chapter?.id,
      classes: data?.classes[0],
      subject_id: data?.subject?.id,
      event_category_id: data?.event_category?.id,
      teacher: data?.teacher[0],
      id: data?.id,
    });
  };

  // useEffect(() =>{
  //   getClass()
  //   if(classDetails?.classes){
  //     console.log(classes, classDetails?.classes,"useEffectclassDetails");
  //     const newObj = classes.find( item => item?.id === classDetails?.classes)
  //     console.log(newObj,"newObj");
  //     let subject2 = newObj?.subjects_detail || []
  //     setSubject(subject2);
  //     let subject = classDetails?.subject_id

  //     let grade = newObj?.grade
  //     // setSelectGrade(grade);
  //     forEditchapterApi(subject , grade)
  //   }
  // },[classDetails?.id])

  // const forEditchapterApi = (subject, grade) =>{
  //   console.log(subject, grade , "forEditchapterApi");
  //   api_token
  //       .get(`cms/v1/chapter/?subject=${subject}&grade=${grade}&page_size=20`)
  //       .then((res) => {
  //         setChapters(res.data.data);
  //       })
  //       .catch((err) => console.log(err));
  // }

  const OpenMessage = () => {
    let obj = { ...classDetails };
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      } else if (
        (key === "classes" || key === "teacher") &&
        typeof obj[key] === "number"
      ) {
        obj[key] = [obj[key]]; // Convert number to array
      }
    }

    let cat =
      eventCategory &&
      eventCategory.length > 0 &&
      eventCategory?.filter((data) => data?.title == "School");
    obj.event_category_id = cat[0]?.id;
    let holidays =
      eventType &&
      eventType.length > 0 &&
      eventType?.filter((data) => data?.title == "Holidays");
    obj.event_type_id = holidays[0]?.id;
    console.log(obj, "OBJDHEHHE");

    delete obj?.id;

    let checkData ={
      date:obj?.start,
      commence:obj?.commence,
      conclude:obj?.conclude,

    }
    api_token
      .post(`calendar/v1/events/validate_event/`, checkData)
      .then((res) => {
        const result = res.data.data[0];
        setOpen(false);
        setMessage(result);
        setOpenDialog(true);
      })
      .catch((err) => {});
  };

  const SubmitData = () => {
    let obj = { ...classDetails };
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      } else if (
        (key === "classes" || key === "teacher") &&
        typeof obj[key] === "number"
      ) {
        obj[key] = [obj[key]]; // Convert number to array
      }
    }

    let cat =
      eventCategory &&
      eventCategory.length > 0 &&
      eventCategory?.filter((data) => data?.title == "School");
    obj.event_category_id = cat[0]?.id;
    let holidays =
      eventType &&
      eventType.length > 0 &&
      eventType?.filter((data) => data?.title == "Holidays");
    obj.event_type_id = holidays[0]?.id;
    console.log(obj, "OBJDHEHHE");

    delete obj?.id;

    if (classDetails?.id) {
      console.log(classDetails?.id, obj, "hitpatch ");

      api_token
        .patch(`calendar/v1/events/${classDetails?.id}/`, obj)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            let mnth;
            let yrs;
            const { month, year } = currenctDate;
            mnth = month + 1;
            yrs = year;
            getListing(mnth, yrs);
            setOpen(!open);
            setClassDetail({});
            alert('Data Updated Successfully ')
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log(classDetails?.id, obj, "hitpatch ");
      api_token
        .post(`calendar/v1/events/create_events/`, obj)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            let mnth;
            let yrs;
            const { month, year } = currenctDate;
            mnth = month + 1;
            yrs = year;
            getListing(mnth, yrs);
            setOpen(!open);
            alert('Data Added Successfully ')

          }
        })
        .catch((err) => console.log(err));
    }
    // api_token
    // .post(`calendar/v1/events/create_events/`, obj)
    // .then((res) => {
    //   if (res.status == 200 || res.status == 201) {
    //     let mnth;
    //     let yrs;
    //     const { month, year } = currenctDate;
    //     mnth = month + 1;
    //     yrs = year;
    //     getListing(mnth, yrs);
    //     setOpen(!open)
    //   }
    // })
    // .catch((err) => console.log(err));
  };
  let events =
    eventCategory &&
    eventCategory.length > 0 &&
    eventCategory?.filter((data) => data?.title == "School");
  let holidays =
    eventType &&
    eventType.length > 0 &&
    eventType?.filter((data) => data?.title == "Holidays");

  console.log(
    eventCategory.filter((data) => data.title == "School"),
    "calendarDatass"
  );
  return (
    <div>
      <div className={styles.mainData}>
        <Button variant="contained" onClick={handleClicked}>
          Add Holiday
        </Button>
      </div>
      <div>
        <CustomCalendar
          calendarData={calendarData}
          setDateTime={setDateTime}
          onUpdateCalendarData={handleCalendarDataUpdate}
        />
      </div>
      <div>
        <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
          <div className={styles.drawerContain}>
            <h2>Add Holiday</h2>
            <div className={styles.textData}>
              <TextField
                name="title"
                label="Title"
                style={{ width: "100%" }}
                value={classDetails?.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                name="description"
                label="Description"
                style={{ width: "100%" }}
                value={classDetails?.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="start"
                label="start date"
                value={classDetails?.start}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="end"
                label="end date"
                value={classDetails?.end}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="commence"
                label="start time"
                value={classDetails?.commence}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="conclude"
                label="end time"
                value={classDetails?.conclude}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event for</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_for"
                  value={classDetails?.event_for}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Student</MenuItem>
                  <MenuItem value={3}>Teacher</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Type</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_type_id"
                  value={holidays[0]?.id}
                  disabled
                  label="Event Type"
                  onChange={handleDropdownChange}
                >
                  {eventType &&
                    eventType.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Category</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_category_id"
                  value={events[0]?.id}
                  label="Event Category"
                  disabled
                  onChange={handleDropdownChange}
                >
                  {eventCategory &&
                    eventCategory.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <Button onClick={() => SubmitData()} variant="contained">  
                Submit
              </Button>
              {/* change this submitdata function to  OpenMessage function*/}
            </div>
          </div>
        </DrawerComp>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>{message?.message}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={SubmitData}>Override and schedule</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Holiday;


import React, { useEffect, useState } from 'react';
import { Calendar, globalizeLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize';
import { myEventsList } from './Event.js';
import { api_token } from '../../Utils/Network.js';
import { convertCalendarDate, getCurrentDateTime } from '../../Utils/Utils.js';
import { useLocation } from 'react-router-dom';
import freePeriodimg from "../../Assets/Free Period.png"
import breakimg from "../../Assets/Break.png"

const localizer = globalizeLocalizer(globalize)



function CustomCalendar({ calendarData, onUpdateCalendarData, setDateTime = () => { } }) {
    // const [calendarData, setCalendarData] = useState([]);
    // const [dateTime, setDateTime] = useState(getCurrentDateTime());
    // console.log(dateTime, 'date');
    // useEffect(() => {
    //     getListing();
    // }, [dateTime])

    // const getListing = () => {
    //     const { month, year } = dateTime;
    //     api_token.get(`calendar/v1/events/?month=${month+1}&year=${year}`)
    //         .then(response => {
    //             console.log(response)
    //             const data = convertCalendarDate(response.data.data);
    //             setCalendarData(data)
    //         })
    //         .catch(error => {

    //         })
    // }
    const [isMonthView, setIsMonthView] = useState(true);
    const handleViewChange = (view) => {
        console.log(view, "handleViewChange");
        setIsMonthView(view === 'month' || view === 'agenda');
        if (view === 'month' || view === 'agenda') {
            setIsMonthView(true);
        } else {
            setIsMonthView(false)
        }
    };
    console.log(calendarData, 'calendarData')

    const getEvent = (e) => {
        console.log(e)
    }

    const onEventClick = (e) => {
        console.log(e)
        onUpdateCalendarData(e)
    }

    const onSlotChange = (e) => {
        console.log(e)
    }

    const formats = {
        eventTimeRangeFormat: () => {
            return "";
        },
    };


    // Custom Event Component
    const CustomEventComponent = ({ event }) => {
        const [isHovered, setIsHovered] = useState(false);
        console.log(event, "CustomEventComponent");
        return (
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            // style={{position:"relative", overflow:"visible"}}
            >
                <div style={{ fontSize: "12px", fontWeight: "500", padding: ' 2px', boxSizing: "border-box", whiteSpace: "nowrap" }}>{event?.title}</div>
                {isHovered && (
                    <div style={{
                        position: 'absolute',
                        top: '-20px',
                        left: `${isMonthView ? "50%" : "20%"}`,
                        zIndex: 999,
                        // backgroundColor: 'white',
                        // background: `${(event?.title?.props?.children?.props?.children == "Free Period" || event?.title?.props?.children?.props?.children == "Break" || event?.event_type?.title == "Holidays") ? "#000" : `linear-gradient(103.47deg, ${event?.subject?.light_colour} 2.26% , ${event?.subject?.dark_colour} 96.35%)`} `,
                        // border: '1px solid black',
                        background : `${
                            (event?.title?.props?.children?.props?.children == "Free Period") ? 
                            `linear-gradient(145deg, rgba(24,214,129,1) 0%, rgba(225,236,97,1) 100%)` : 
                            (event?.title?.props?.children?.props?.children == "Break") ?
                            `linear-gradient(145deg, rgba(255,95,95,1) 0%, rgba(204,245,245,1) 100%)` :
                            (event?.event_type?.title == "Holidays") ?
                            "#000" :
                            `linear-gradient(103.47deg, ${event?.subject?.light_colour} 2.26% , ${event?.subject?.dark_colour} 96.35%)`
                        }`,
                        padding: '2px',
                        borderRadius: "8px",
                    }}>
                        {/* <p>{event.title}</p> */}
                        {/* Add any extra data you want to display */}
                        <div style={{ position: "relative", fontSize: "12px", fontWeight: "500", lineHeight: 1.3, padding: "2px", boxSizing: "border-box", whiteSpace: "nowrap" }}>
                            <div> {event?.title?.props?.children?.props?.children}</div>
                            {/* <div>{event?.id}</div>
                            <div> {event?.date}</div> */}

                            <div style={{ display: "flex", gap: '5px' }}>
                                {
                                    (event?.title?.props?.children?.props?.children == "Free Period") ? <img width={50} height={60} src={freePeriodimg} /> : (event?.title?.props?.children?.props?.children == "Break") ? <img width={50} height={60} src={breakimg} /> : <img width={50} height={60} src={(event?.subject?.icon) ? event?.subject?.icon : event?.subject?.icon1} />
                                }
                                {/* <img width={50} height={60} src={(event?.subject?.icon) ? event?.subject?.icon : event?.subject?.icon1} /> */}

                                <div style={{ color: "#fff" }}>
                                    <p style={{ margin: "1px 0 " }}> {event?.start_time} - {event?.end_time} </p>
                                    <span style={{ margin: "1px 0 ", padding: "2px 5px", backgroundColor: "#FF54A4", borderRadius: "10px" }}>{event?.event_type?.title}</span>
                                    <p style={{ margin: "1px 0 " }}> {(event?.class_info[0]?.title) ? event?.class_info[0]?.title : ""}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "flex-end" }}>
                                {
                                    (event?.teacher_info[0]?.avatar == null) ? "" : <img width={25} height={25} style={{ borderRadius: "25px", border: '1px solid #FFF' }} src={event?.teacher_info[0]?.avatar} />
                                }
                                {/* <img width={25} height={25} style={{ borderRadius: "25px", border: '1px solid #FFF' }} src={event?.teacher_info[0]?.avatar} /> */}
                                <p style={{ margin: "1px 0 ", fontSize: "12px" }}>{event?.teacher_info[0]?.first_name} {event?.teacher_info[0]?.last_name}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <Calendar
                localizer={localizer}
                events={calendarData}
                components={{ event: CustomEventComponent }}
                formats={formats}
                tooltipAccessor={null}
                startAccessor="start"
                endAccessor="end"
                selectable
                style={{ height: '100vh' }}
                onClick={(e) => getEvent(e)}
                onSelectEvent={event => onEventClick(event)}
                onSelectSlot={(slotInfo) => onSlotChange(slotInfo)}
                onView={(view) => {
                    console.log('#### onView');
                    console.log('#### view=', view);
                    //this.setState({currentView: view});
                    handleViewChange(view)
                }}
                onNavigate={(date, view) => {
                    console.log('#### onNavigate');
                    console.log('#### date=', date);
                    console.log('#### view=', view);
                    setDateTime(date)
                    // changeDate(view, date);
                    //this.setState({currentDate: date}); }}
                }}
                eventPropGetter={
                    (event, start, end, isSelected) => {
                        let newStyle = {
                            // backgroundColor: `${(event?.subject?.light_colour) ? event?.subject?.light_colour : '#3174ad'}`,
                            // background: `linear-gradient(103.47deg, ${event?.subject?.light_colour} 2.26% , ${event?.subject?.dark_colour} 96.35%)`,
                            background: `${(event?.title?.props?.children?.props?.children == "Free Period") ? 
                            `linear-gradient(145deg, rgba(24,214,129,1) 0%, rgba(225,236,97,1) 100%)` :
                             (event?.title?.props?.children?.props?.children == "Break") ? 
                             `linear-gradient(145deg, rgba(255,95,95,1) 0%, rgba(204,245,245,1) 100%)` : 
                             `linear-gradient(103.47deg, ${event?.subject?.light_colour} 2.26% , ${event?.subject?.dark_colour} 96.35%)`} `,
                            // color: 'black',
                            // color: `${(event?.subject?.dark_colour) ? event?.subject?.dark_colour : 'black'}`,
                            color: 'white',
                            borderRadius: "10px",
                            border: "none",
                            overflow: "visible",
                            // position: "relative",
                        };

                        // if (event.color) {
                        //   newStyle.backgroundColor = "lightgreen"
                        // }
                        if (isMonthView) {
                            newStyle.position = "relative";

                        } else if( event?.title?.props?.children?.props?.children == "Break") {
                            newStyle.padding = "2px";
                        }else{
                            newStyle.padding = "5px";
                        }

                        return {
                            className: "",
                            style: newStyle
                        };
                    }
                }

            // popup
            // showMultiDayTimes
            // startAccessor="start"
            // endAccessor="end"   
            />
        </div>
    )
}

export default CustomCalendar; 